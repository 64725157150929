import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, TextInput, Button, Layer } from 'grommet';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { firestore } from 'firebase/app'
import queryString from 'query-string'
import Loader from 'react-loader-spinner'

import VideoRoom from '../components/VideoRoom';

const RoomHost = ({ history }) => {

    const { id } = useParams();
    const { s } = queryString.parse(history.location.search)

    // const userID = useContext(UserContext)

    const [data, setData] = useState({ image: "" })
    const [loading, setLoading] = useState(true)
    const [retrievingData, setRetrievingData] = useState(false)

    const [sessionID, setSessionID] = useState(id)
    const [userName, setUserName] = useState("")
    const [userTeam, setUserTeam] = useState("")

    const [usersPassword, setUsersPassword] = useState("")
    const [password, setPassword] = useState("")
    const [wrongPassword, setWrongPassword] = useState(false)

    const [token, setToken] = useState(undefined)

    const [confirmation, setConfirmation] = useState(false)
    const [errorLoading, setErrorLoading] = useState(false)


    useEffect(() => {
        const listener = id && firestore().collection("rooms").doc(id).onSnapshot(doc => {
            if (doc.exists) {
                setData({ ...doc.data(), id: doc.id })
                s && setPassword(s)
                s && !token && joinSession()
                setLoading(false)
                setErrorLoading(false)
            } else {
                console.log("error")
                setLoading(false)
                setErrorLoading(true)
            }
        })
        recoverUserTeam()

        return () => listener()
    }, [])


    const recoverUserTeam = async () => {
        const name = await localStorage.getItem("userName", userName)
        const team = await localStorage.getItem("userTeam", userTeam)
        await setUserName(name)
        await setUserTeam(team)
    }


    const joinSession = async () => {
        setRetrievingData(true)
        setWrongPassword(false)
        const variables = {
            password: password ? password : s ? s : password,
            sessionID
        }
        axios.post("https://europe-west1-novanetlive-main.cloudfunctions.net/api/startRoom", {
            method: 'POST',
            body: JSON.stringify(variables)
        }).then((response) => {
            // console.log('TOKEN', response)
            if (data.message === "Error") {
                setWrongPassword(true)
            }
            if (response.data) {
                setToken(response.data.token)
                setUserName(response.data.user.userName)
                setUserTeam(response.data.user.userTeam)
                setUsersPassword(response.data.user.password)
            } else {
                setWrongPassword(true)
            }
            setRetrievingData(false)
        }).catch((error) => {
            console.log(error)
            setRetrievingData(false)
        })
    }


    return (
        <Box flex="grow">
            {loading ?
                <Box />
                :
                errorLoading ?
                    <Box>
                        <Box flex="grow" align="center" >
                            <RomHeader title={data.title} image={data.image} />
                            <Box style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px">
                                <Heading margin="none" textAlign="center" level="3">Ha ocurrido un error accediendo a la sala, la sala que buscas no existe o no se encuentra disponible</Heading>
                                <Box align="center">
                                    <Button
                                        primary
                                        href="/"
                                        label={"Salir"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    :
                    !token ?
                        <Box>
                            <Box flex="grow" align="center" >
                                <RomHeader title={data.title} image={data.image} />
                                <Box style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px">

                                    {!retrievingData ?
                                        <Box>
                                            <Heading margin="none" textAlign="center" level="3">Accede a la sala</Heading>
                                            <Box gap="small">
                                                <Text size="small">PIN Inicio de Sesión</Text>
                                                <TextInput value={password || ""} style={{ fontSize: 16 }} placeholder="Contraseña..." onChange={(ev) => setPassword(ev.target.value)} type="password" />

                                                {wrongPassword && <Text textAlign="center" color="status-critical" >El PIN introducido es incorrecto.</Text>}
                                            </Box>

                                            <Box align="center" margin={{top:"20px"}}>
                                                <Button
                                                    primary
                                                    disabled={userName === "" || userTeam === "" || (data.protected && password === "") || retrievingData}
                                                    onClick={() => joinSession()}
                                                    label={retrievingData ? "Accediendo" : "Acceder"}
                                                />
                                            </Box>
                                        </Box>
                                        :
                                        <Box height={{min:"small"}} flex="grow" align="center" justify="center" gap="medium">
                                            <Loader type="Puff" color="#002C50" height={60} width={60}/>
                                            <Text textAlign="center">Accediendo a la sala...</Text>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            <Text color="gray" textAlign="center" style={{ cursor: "pointer", marginTop: 20, marginBottom: 50 }} onClick={() => history.push(`/sala/${id}`)}>Soy un invitado</Text>
                        </Box>
                        :
                        <VideoRoom
                            token={token}
                            closeSessionCallback={() => {
                                setToken(undefined)
                                history.push("/")
                            }}
                            clientData={{ userName, userTeam, password: usersPassword }}
                            id={id}
                            data={data}
                            password={password}
                        />
            }

            {confirmation &&
                <Layer>
                    <Box background="white" pad="medium" round="small" elevation="large">
                        <Heading level="3" textAlign="center">¿Estás seguro que deseas cerrar esta sesión?</Heading>
                    </Box>
                </Layer>
            }
        </Box >
    );
}


export default RoomHost;

const RomHeader = ({ title, image }) => {
    return (
        <Box style={{ position: "relative" }} height="small" width="100%" align="center" pad={{ top: "50px" }} background={`url('${image}')`}>
            <Box style={{ position: "absolute", top: 0 }} background="rgba(0,0,0,.8)" fill />
            <Heading style={{ zIndex: 2 }} color="white" margin="none" level="2">{title}</Heading>
        </Box>
    )
}