import React, { useEffect, useState, useRef } from 'react';
import { Button, Box } from 'grommet';
import * as Icon from 'grommet-icons'

const VideoComponent = ({ stream, contain, style = {} }) => {

    const videoRef = useRef()
    const [cover, setCover] = useState(contain)


    useEffect(() => {
        stream.addVideoElement(videoRef.current)
    }, [stream])

    return (
        <Box>
            <Button style={{position:"absolute", top:0, right:0, zIndex:10}} onClick={() => setCover(!cover)} hoverIndicator icon={cover ? <Icon.Expand size="15px"/> : <Icon.Contract size="15px"/>} />
            <video style={{ ...style, objectFit: cover ? "cover" : "contain" }} autoPlay={true} playsInline={true} ref={videoRef} />
        </Box>
    );
}

export default VideoComponent;