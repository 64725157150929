import React, { useEffect, useState, useRef } from 'react';
import { Box, Text } from 'grommet'

const SessionTimer = ({ offset = 0 }) => {

    const [time, setTime] = useState({ seconds: 0, minutes: 0, hours: 0 })
    const timeRef = useRef(offset)

    useEffect(() => {
        var x = setInterval(function () {

            timeRef.current = timeRef.current + 1

            var seconds = Math.floor((timeRef.current % 60));
            var minutes = Math.floor(((timeRef.current / 60) % 60));
            var hours = Math.floor(((timeRef.current / 60 / 24) % 60));

            setTime({ hours, minutes, seconds })

        }, 1000);
        return () => clearInterval(x)
    })

    const n = (n) => {
        return n > 9 ? "" + n : "0" + n;
    }

    return (
        <Box background="#e8e8e8" round="7px" pad={{ vertical: "5px", horizontal: "10px" }}>
            <Text weight="bold" size="large">{n(time.hours)} : {n(time.minutes)} : {n(time.seconds)}</Text>
        </Box>
    )
}

export default SessionTimer