import React, { useEffect, useState, useContext } from 'react';
import { Box, Heading, Grid, Text, ResponsiveContext, Image } from 'grommet';
import * as Icons from 'grommet-icons'
import BG from '../assets/img/bgn.jpg'
import LogoNova from '../assets/img/logo.png'
import moment from 'moment'

import { firestore } from 'firebase/app'
import { Link } from 'react-router-dom';



const Home = () => {

    const [data, setData] = useState([])
    const size = useContext(ResponsiveContext);

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        const currentTime = new Date()
        firestore().collection("rooms")
            .where("private", "==", false)
            .where("ended", "==", false)
            // .where("live", "==", true)
            .where('ends', '>', currentTime).orderBy("ends", "asc").get().then(res => {
                setData(res.docs.map(d => ({ ...d.data(), id: d.id })))
            }).catch(err => {
                console.log(err)
            })
    }

    return (
        <Box flex="grow">
            <Box height="200px" pad="medium" align="center" justify="center" background={`url(${BG})`}>
                <Box width="medium" height="45px">
                    <Image src={LogoNova} fit="contain" />
                </Box>
            </Box>
            <Box align="center" flex="grow">
                <Box pad="medium" flex="grow" margin={{ bottom: "30px" }} style={{ width: "100%" }} width={{ max: "xlarge" }}>
                    {data.length === 0 ?
                        <Box flex="grow" align="center" justify="center">
                            <Heading textAlign="center" level="2" color="gainsboro">No hay salas disponibles</Heading>
                        </Box>
                        :
                        <Grid
                            columns={{ count: size === "small" ? 1 : 3, size: "auto" }}
                            gap={size === "small" ? "large" : "medium"}
                        >
                            {data.map((room, i) => {

                                return room.participants > 20 ?
                                    <a key={i} href={room.zoom && room.zoom.includes("http") ? room.zoom : `https://${room.zoom}`} rel="noopener noreferrer" target="_blank" style={{ color: "none", textDecoration: "none" }}>
                                        <Box style={{ position: 'relative' }} background={`url('${room.image}')`} height="small" round="small" elevation="medium" >
                                            <Box round="small" fill style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} background="rgba(0,0,0,.7)" />
                                            <Box fill pad="medium" style={{ zIndex: 2 }} justify="between">
                                                <Box>
                                                    <Heading style={{ textDecoration: "none" }} level="4" margin="none" color="white">{room.title}</Heading>
                                                </Box>
                                                <Box gap="7px" style={{ position: "absolute", bottom: -10 }}>
                                                    <Box pad="small" alignSelf="start" round="5px" elevation="medium" background={{ color: "white" }}>
                                                        <Text color="gray" weight="bold" size="small">{moment(room.starts.toDate()).format("DD-MMMM")}</Text>
                                                        <Text color="brand" size="22px" weight="bold">{moment(room.starts.toDate()).format("HH:mm")} : {moment(room.ends.toDate()).format("HH:mm")}</Text>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {room.protected &&
                                                <Box pad="small" round="7px" elevation="large" background="white" style={{ position: "absolute", top: -10, right: -10 }}>
                                                    {!room.live ? <Icons.Lock color="brand" /> : <Icons.CirclePlay className="live" color="status-critical"/>}
                                                </Box>
                                            }
                                        </Box>
                                    </a>
                                    :
                                    <Link style={{ textDecoration: "none" }} to={`/sala/${room.id}`} key={i}>
                                        <Box style={{ position: 'relative' }} background={`url('${room.image}')`} height="small" round="small" elevation="medium" >
                                            <Box round="small" fill style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} background="rgba(0,0,0,.7)" />
                                            <Box fill pad="medium" style={{ zIndex: 2 }} justify="between">
                                                <Box flex>
                                                    <Heading style={{ textDecoration: "none" }} level="4" margin="none" color="white">{room.title}</Heading>

                                                    {/* <Paragraph truncate={true} color="white" size="small">{room.description.slice(0, 80)}{room.description.length > 80 ? "..." : ""}</Paragraph> */}
                                                </Box>
                                                <Box gap="7px" style={{ position: "absolute", bottom: -10 }}>
                                                    <Box pad="small" alignSelf="start" round="5px" elevation="medium" background={{ color: "white" }}>
                                                        <Text color="gray" weight="bold" size="small">{moment(room.starts.toDate()).format("DD-MMMM")}</Text>
                                                        <Text color="brand" size="22px" weight="bold">{moment(room.starts.toDate()).format("HH:mm")} : {moment(room.ends.toDate()).format("HH:mm")}</Text>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {room.protected &&
                                                <Box pad="small" round="7px" elevation="large" background="white" style={{ position: "absolute", top: -10, right: -10 }}>
                                                    {!room.live ? <Icons.Lock color="brand" /> : <Box direction="row" gap="5px" align="center"><Box className="live" width="15px" height="15px" round="10px" background="status-critical"/><Text weight="bold" size="small">Live</Text></Box>}
                                                </Box>
                                            }
                                        </Box>
                                    </Link>
                            })}
                        </Grid>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default Home;