import React, { useMemo, useEffect } from 'react'
import styled from 'styled-components'
import {
    useTable,
    usePagination,
    useFilters,
    useSortBy,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
} from 'react-table'
import { Box, Text, TextInput, Button } from 'grommet'
import * as Icon from 'grommet-icons'
import matchSorter from 'match-sorter'



// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length
    return (
        <TextInput
            style={{ height: 35, marginTop: 10, backgroundColor:"white" }}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Buscar...`}
        />
    )
}



function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val


const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
    props,
    {
        style: {
            alignItems: align === "center" ? "center" : align === 'right' ? 'flex-end' : 'flex-start',
            justifyContent: 'center',
            display: 'flex',
        },
    },
]

const Table = ({ columns, data, onFetchData, loading }) => {

    // useEffect(() => {
    //     onFetchData({ pageIndex, pageSize, sortBy, filters })
    // }, [onFetchData, pageIndex, pageSize, sortBy, filters])

    const filterTypes = useMemo(() => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
        },
    }),
        []
    )

    const defaultColumn = useMemo(() => ({
        // Let's set up our default Filter UI
        minWidth: 70,
        width: 150,
        maxWidth: 400,
        Filter: DefaultColumnFilter,
    }),
        []
    )


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize,
            sortBy,
            filters
        },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: { pageIndex: 0 },
        },
        // useBlockLayout,
        useFilters,
        useSortBy,
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
        usePagination,
        hooks => {
            // hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
            //     // fix the parent group of the selection button to not be resizable
            //     const selectionGroupHeader = headerGroups[0].headers[0]
            //     selectionGroupHeader.canResize = false
            // })
        }
    )

    return (
        <Styles>
            <Box flex="grow">

                <Box className="table" round={{size:"small", corner:"top"}} {...getTableProps()} >

                    {headerGroups.map(headerGroup => (
                        <Box
                            {...headerGroup.getHeaderGroupProps({
                                // style: { paddingRight: '15px' },
                            })}
                            background="#f9f9f9"
                            round={{ corner: "top", size: "small" }}
                            direction="row"
                            className="tr"
                        >

                            {headerGroup.headers.map(column => (
                                <Box className="th" {...column.getHeaderProps(headerProps)}>
                                    <Box focusIndicator={false} direction="row" gap="small" align="center" {...column.getSortByToggleProps()}>
                                        {column.render('Header')}

                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <Icon.Down size="small"/>
                                                    : <Icon.Up size="small"/>
                                                : ''}
                                    </Box>
                                    <Box >{column.canFilter ? column.render('Filter') : null}</Box>
                                    {column.canResize && (
                                        <Box
                                            {...column.getResizerProps()}
                                            className={`resizer ${
                                                column.isResizing ? 'isResizing' : ''
                                                }`}
                                        />
                                    )}
                                </Box>

                            ))}
                        </Box>
                    ))}


                    <Box
                        className="tbody"
                        {...getTableBodyProps({
                            // style: { paddingRight: '15px' },
                        })}
                    >
                        {page.length === 0 ?
                            <Box height="small" align="center" justify="center">
                                <Text>{loading ? "Cargando Información" : "Sin Datos"}</Text>
                            </Box>
                            :
                            page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <Box direction="row" {...row.getRowProps()} className="tr">
                                        {row.cells.map(cell => {
                                            return <Box className="td" {...cell.getCellProps(cellProps)}>{cell.render('Cell')}</Box>
                                        })}
                                    </Box>
                                )
                            })}
                    </Box>

                </Box>

                <Box direction="row" round={{ corner: "bottom", size: "small" }} border={{ color: "gainsboro", size: "1px" }} background="#f9f9f9" pad="small" gap="small" justify="between" className="pagination">
                    <Button primary onClick={() => previousPage()} disabled={!canPreviousPage} label="Anterior" />
                    <Button primary onClick={() => nextPage()} disabled={!canNextPage} label="Siguiente" />
                    {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select> */}
                </Box>
            </Box>
        </Styles>
    );
}

export default Table;


const Styles = styled.div`
padding: 1rem;
display: block;
overflow: auto;

.table {
    border-spacing: 0;
    border: 1px solid gainsboro;

    .thead {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .tbody {
        overflow-y: scroll;
        overflow-x: hidden;
        height: auto;
        max-height:800px;
    }

    .tr {
        :last-child {
            .td {
                border-bottom: 0;
            }
        }
        flex-shrink: 0 1 auto !important;
        border-bottom: 1px solid gainsboro;
    }

    .th,
    .td {
        margin: 0;
        padding: 0.5rem;
        border-right: 1px solid gainsboro;
        width:auto;
        position: relative;

        :last-child {
            border-right: 0;
        }

        .resizer {
            right: 0;
            border-radius:5px;
            width: 5px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            ${'' /* prevents from scrolling while dragging on touch devices */}
            touch-action :none;

            &.isResizing {
            background: gray;
            }
        }
    }
}
`