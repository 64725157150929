import React, { useState, useRef } from 'react'
import { Footer as FooterGrommet, Text, Anchor, Layer, Box, Drop, Heading, Button, TextInput, TextArea, Select } from 'grommet'
import { useLocation } from 'react-router-dom'
import * as Icon from 'grommet-icons'
import { firestore } from 'firebase'
import Loader from 'react-loader-spinner'

const Footer = ({ showMenu }) => {

    const { pathname } = useLocation()

    const butRef = useRef()
    const [form, setForm] = useState({
        email: "",
        type: "",
        notes: ""
    })
    const [help, toogleHelp] = useState(false)
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);

    const sendSupport = () => {
        setLoading(true)
        firestore().collection("support").add({
            ...form,
            fixed: false,
            createdAt: firestore.FieldValue.serverTimestamp()
        })
            .then(res => {
                setLoading(false)
                setSent(true)
            })
            .catch(err => setError(true))
    }

    return (
        <Box>
            <FooterGrommet
                // style={{ position: "absolute", bottom: 0, width: "100%" }} 
                style={{ display: showMenu ? "flex" : "none" }}
                height="50px" background={pathname.includes('login') ? "#171717" : "linear-gradient(18deg, black, #015886)"} pad={{ horizontal: "medium" }}>
                <Text color="white" size="small">&reg; 2020 - Powered by <Anchor size="small" style={{ fontWeight: "normal" }} label="novanet" color="white" target="_blank" href="https://www.novanet.es" /> - <Text size="small" style={{ opacity: .5 }}>v2.0.5</Text></Text>
                <Text color="white" size="small" onClick={() => toogleHelp(true)}>Soporte</Text>

            </FooterGrommet>



            {
                help &&
                <Layer
                    onEsc={() => toogleHelp(false)}
                    onClickOutside={() => toogleHelp(false)}
                    position="bottom-right"
                >
                    <Box direction="row" pad={{ left: "small" }} justify="between" align="center">
                        <Box flex>
                            <Heading level="3" margin="none">Soporte</Heading>
                        </Box>
                        <Button hoverIndicator onClick={() => toogleHelp(false)} icon={<Icon.Close />} />
                    </Box>
                    {/* <ReactTooltip place="left" type="warning" effect="solid" /> */}
                    {!sent ?
                        <Box pad="medium" gap="medium" width={{ max: "medium" }}>
                            <Text>Si has tenido cualquier incidencia con la plataforma háznoslo saber y te daremos una solución cuanto antes.</Text>
                            <Box gap="5px">
                                <Text size="small" color="gray">Email</Text>
                                <TextInput value={form.email} onChange={ev => setForm({ ...form, email: ev.target.value })} placeholder="Email" />
                            </Box>
                            <Box gap="5px">
                                <Text size="small" color="gray">Tipo de asistencia</Text>
                                <Select
                                    value={form.type}
                                    placeholder="Selecciona"
                                    onChange={({ option }) => setForm({ ...form, type: option })}
                                    options={["Asistencia técnica", "Incidencia", "Otra"]}
                                />
                            </Box>
                            <Box gap="5px">
                                <Text size="small" color="gray">Notas</Text>
                                <TextArea resize='vertical' value={form.notes} onChange={ev => setForm({ ...form, notes: ev.target.value })} placeholder="Notas" style={{ height: 150 }} />
                            </Box>

                            {!loading ?
                                <Button disabled={form.email === "" || form.type === "" || form.notes === ""} primary label="Enviar" onClick={() => sendSupport()} />
                                :
                                <Box align="center">
                                    <Loader type="Puff" color="#002c4f" height={45} width={45} />
                                </Box>
                            }
                        </Box>
                        :
                        <Box pad="medium" gap="medium" width={{ max: "medium" }}>
                            <Text>Hemos recibido tu solicitud con éxito, nos pondremos en contacto cuanto antes. Muchas gracias y disculpe las molestias</Text>
                            <Button primary label="Volver" onClick={() => {
                                setSent(false)
                                toogleHelp(false)
                            }} />
                        </Box>
                    }


                </Layer>
            }
        </Box>
    )
}

export default Footer