import React, { useState, useRef, useContext } from 'react';
import { Box, Layer, TextInput, TextArea, Text, Button, MaskedInput, DropButton, Calendar, CheckBox } from 'grommet';
import { firestore, storage } from 'firebase/app'
import * as Icon from 'grommet-icons'
import moment from 'moment'
import UserContext from '../context/UserContext';
import defImg from '../assets/img/default.jpg'
import Loader from 'react-loader-spinner'


const NewRoomModal = ({ data = {}, closeCallback }) => {


    const userID = useContext(UserContext)

    const fileRef = useRef()

    const initForm = {
        title: data.title || "",
        description: data.description || "",
        participants: data.participants || "",
        starts: data.starts || "",
        organization: data.contact || "",
        email: data.email || "",
        contact: data.contact || "",
        zoom: data.zoom || "",
        ends: data.ends || "",
        private: data.private || false,
        admin: userID,
    }

    const [newRoomForm, setNewRoomForm] = useState(initForm)
    const [displayStarts, setDisplayStarts] = useState("")
    const [displayEnds, setDisplayEnds] = useState("")
    const [schedule, openSchedule] = useState(false)
    const [saving, setSaving] = useState(false)
    const [imageURL, setImageURL] = useState(defImg)
    const [file, setFile] = useState(null)

    const createRoom = async () => {
        setSaving(true)
        const startTime = moment(moment(newRoomForm.date).format("YYYY-MM-DD") + "T" + newRoomForm.starts)
        const endTime = moment(moment(newRoomForm.date).format("YYYY-MM-DD") + "T" + newRoomForm.ends)
        var duration = moment.duration(endTime.diff(startTime))

        if (newRoomForm.date === "") {
            alert("Introduce la fecha de la reunión")
            return
        } else if (newRoomForm.starts === "") {
            alert("Introduce la hora de inicio de la reunión")
            return
        } else if (newRoomForm.ends === "") {
            alert("Introduce la hora de finalización de la reunión")
            return
        } else if (startTime < moment()) {
            alert("La hora de inicio ya ha pasado")
            return
        } else if (duration.asHours() > 2) {
            // alert("La duración máxima de una reunión puede ser 2 horas")
            // return
        }
        // else if (duration < 0) {
        //     alert("La hora de inicio de la reunión no puede ser menor que la final")
        //     return
        // } 
        // else if (newRoomForm.private && newRoomForm.password === "") {
        //     alert("Las reuniones privadas requieren introducir una contraseña")
        //     return
        // }


        const fileToUpload = file ? storage().ref().child(`rooms/images/${newRoomForm.title}-${new Date()}-roomImage.jpg`) : null
        const roomImage = fileToUpload ? await fileToUpload.put(file).then((res) => res.ref.getDownloadURL()) : defImg

        const variables = {

            title: newRoomForm.title || "",
            description: newRoomForm.description || "",
            participants: newRoomForm.participants !== "" ? parseInt(newRoomForm.participants) : 20,
            starts: startTime.toDate(),
            ends: endTime.toDate(),
            createdAt: firestore.FieldValue.serverTimestamp(),
            ended: false,
            secured: true,
            protected: true,
            image: roomImage || defImg,
            zoom: newRoomForm.zoom ? newRoomForm.zoom : "",
            private: newRoomForm.private,
            webinar: false,
            waitingRoom: [],
            granted: [],
            banned: []

        }

        const privatevariables = {
            adminpin: Math.floor(100000 + Math.random() * 900000),
            password: Math.floor(Math.random() * 899999 + 100000),
            zoompassword: newRoomForm.zoompassword || "",
            zoom: newRoomForm.zoom || "",
            protected: true,
            contact: newRoomForm.contact || "",
            description: newRoomForm.description || "",
            participants: parseInt(newRoomForm.participants) || "",
            organization: newRoomForm.organization || "",
            email: newRoomForm.email || "",
            title: newRoomForm.title || "",
            ends: endTime.toDate() || "",
            starts: startTime.toDate() || "",
        }

        firestore().collection("roomsprivateinfo").add(privatevariables).then(res => {
            console.log(res)
            const salaID = res.id
            firestore().collection("rooms").doc(salaID).set(variables)
                .then(res => {
                    console.log(res)
                    firestore().collection("roomrequests").doc(salaID).delete().then(res => {
                        closeCallback()
                        setNewRoomForm(initForm)
                        setSaving(false)
                    }).catch(err => {
                        setSaving(false)
                        alert("Ha ocurrido un error al eliminar la solicitud de sala pero la sala se ha creado con éxito.")
                    })
                }).catch(err => {
                    console.log(err)
                    setSaving(false)
                    alert("Ha ocurrido un error al aceptar la solicitud de sala.")
                })
        }).catch(err => {
            setSaving(false)
            alert("Ha ocurrido un error al aceptar la solicitud de sala. No se han podido generar credenciales de seguridad")
        })
        // firestore().collection("rooms").add(variables).then(res => {
        //     console.log(res)
        //     closeCallback()
        //     setNewRoomForm(initForm)
        // })
    }


    return (
        <Layer
            full="vertical"
            position="left"
            onEsc={() => closeCallback()}
            onClickOutside={() => closeCallback()}
        >
            <Box width="medium">
                <Box pad={{ right: "small" }} flex="grow" align="center" direction="row" background="brand">
                    <Box direction="row" flex align="center" gap="small">
                        <Button hoverIndicator icon={<Icon.Close />} onClick={() => closeCallback()} />
                        {/* <Heading level="3" margin="none">{data ? "Actualizar sala" : "Crear nueva sala"}</Heading> */}
                    </Box>
                    {saving ?
                        <Loader type="Puff" color="white" height={45} width={45} />
                        :
                        <Button primary size="small" style={{ borderRadius: 7 }} color="light-1" disabled={newRoomForm.title === ""} label={data.title ? "Actualizar" : "Crear"} onClick={() => createRoom()} />
                    }
                </Box>
                <Box overflow={{ vertical: "scroll", horizontal: "hidden" }} >
                    <Box width="large" pad="medium" gap="medium" flex="grow">
                        <Box gap="small">
                            <Text size="small">Nombre de la Sala</Text>
                            <TextInput value={newRoomForm.title} placeholder="Nombre" onChange={(ev) => setNewRoomForm({ ...newRoomForm, title: ev.target.value })} />
                        </Box>

                        <Box gap="small">
                            <Text size="small">Descripción de la Reunión</Text>
                            <TextArea style={{ minHeight: 150 }} resize="vertical" value={newRoomForm.description} placeholder="Escribe aquí..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, description: ev.target.value })} />
                        </Box>

                        <Box gap="small">
                            <Text size="small">Tipo de Reunión</Text>
                            <CheckBox toggle={true} label={`${newRoomForm.private ? "Privada: Esta reunión no aparecerá en el portal" : "Pública: Esta reunión será visible públicamente en el portal"}`} checked={newRoomForm.private} onChange={(ev) => setNewRoomForm({ ...newRoomForm, private: ev.target.checked })} />
                        </Box>

                        <Box gap="small">
                            <Text size="small">Organizador (Organización o Equipo)</Text>
                            <TextInput value={newRoomForm.organization} placeholder="Organizador..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, organization: ev.target.value })} />
                        </Box>

                        <Box gap="small">
                            <Text size="small">Nombre del Organizador</Text>
                            <TextInput value={newRoomForm.contact} placeholder="Nombre..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, contact: ev.target.value })} />
                        </Box>

                        <Box gap="small">
                            <Text size="small">Email al que notificar</Text>
                            <TextInput value={newRoomForm.email} placeholder="Email..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, email: ev.target.value })} />
                        </Box>

                        {/* {newRoomForm.private &&
                        <Box gap="small">
                            <Text size="small">Contraseña de la Sala</Text>
                            <Box direction="row" style={{ position: "relative" }}>
                                <TextInput pad={{ right: "50px" }} type={hide ? "password" : "text"} value={newRoomForm.password} placeholder="Contraseña" onChange={(ev) => setNewRoomForm({ ...newRoomForm, password: ev.target.value.trim() })} />
                                <Button onClick={() => show(!hide)} style={{ position: "absolute", right: 0, padding: 8 }} plain icon={hide ? <Icon.View /> : <Icon.Hide />} />
                            </Box>
                        </Box>
                    } */}

                        <Box gap="small">
                            <Text size="small">Número Máximo de Participantes</Text>
                            <TextInput type="number" value={newRoomForm.participants} placeholder="Número" onChange={(ev) => setNewRoomForm({ ...newRoomForm, participants: ev.target.value })} />
                        </Box>

                        {newRoomForm.participants > 20 &&
                            <Box gap="small">
                                <Box align="center" justify="center" gap="medium" direction="row" background="light-2" pad={{ horizontal: "medium", vertical: "small" }} round="5px">
                                    <Icon.Alert />
                                    <Text>Cuando el número de participantes supera los 20 se deberá emplear una conferencia de Zoom o cualquier otro servicio.</Text>
                                </Box>
                                <Box gap="small">
                                    <Text size="small">Enlace de Zoom</Text>

                                    <Box direction="row" gap="small" align="center">
                                        <TextInput value={newRoomForm.zoom} placeholder="Enlace" onChange={(ev) => setNewRoomForm({ ...newRoomForm, zoom: ev.target.value })} />
                                        <Button icon={<Icon.Link />} target="_blank" href={newRoomForm.zoom && newRoomForm.zoom.includes("http") ? newRoomForm.zoom : `https://${newRoomForm.zoom}`} />
                                    </Box>
                                </Box>

                            </Box>
                        }

                        <Box gap="small">
                            <Text size="small">Día de la reunión</Text>
                            <DropButton
                                open={schedule}
                                onClose={() => openSchedule(false)}
                                onOpen={() => openSchedule(true)}
                                dropContent={
                                    <Calendar
                                        animate={false}
                                        bounds={[moment().format(), moment().add('years', 2).format()]}
                                        date={newRoomForm.date || moment().format()}
                                        size="small"
                                        daysOfWeek={true}
                                        firstDayOfWeek={1}
                                        locale="es-ES"
                                        onSelect={(ev) => {
                                            console.log(ev)
                                            setNewRoomForm({ ...newRoomForm, date: ev })
                                            openSchedule(false)
                                        }}
                                        showAdjacentDays={false}
                                    />
                                }
                            >
                                <Box direction="row" gap="small" align="center">
                                    <TextInput
                                        // type="date"
                                        placeholder="Selecciona un día"
                                        value={newRoomForm.date
                                            ? `${new Date(newRoomForm.date).toLocaleDateString()}`
                                            : ""} />
                                    <Box pad="small">
                                        <Icon.Schedule />
                                    </Box>
                                </Box>
                            </DropButton>
                        </Box>


                        <Box direction="row" gap="small">
                            <Box gap="small" flex >
                                <Text size="small">Inicio (24h)</Text>
                                <MaskedInput
                                    mask={[
                                        {
                                            length: [1, 2],
                                            regexp: /^[0-1][0-9]|2[0-3]$|^[0-9]$/,
                                            placeholder: "hh"
                                        },
                                        { fixed: ":" },
                                        {
                                            length: 2,
                                            regexp: /^[0-5][0-9]$|^[0-9]$/,
                                            placeholder: "mm"
                                        },
                                    ]}
                                    value={displayStarts}
                                    name="maskedInput"
                                    onChange={event => {
                                        setDisplayStarts(event.target.value)
                                        setNewRoomForm({ ...newRoomForm, starts: moment(event.target.value, ["h:mm A"]).format("HH:mm") })
                                    }}
                                />
                            </Box>


                            <Box gap="small" flex>
                                <Text size="small">Fin (24h)</Text>
                                <MaskedInput
                                    mask={[
                                        {
                                            length: [1, 2],
                                            regexp: /^[0-1][0-9]|2[0-3]$|^[0-9]$/,
                                            placeholder: "hh"
                                        },
                                        { fixed: ":" },
                                        {
                                            length: 2,
                                            regexp: /^[0-5][0-9]$|^[0-9]$/,
                                            placeholder: "mm"
                                        },
                                    ]}
                                    value={displayEnds}
                                    name="maskedInput"
                                    onChange={event => {
                                        setDisplayEnds(event.target.value)
                                        setNewRoomForm({ ...newRoomForm, ends: moment(event.target.value, ["h:mm A"]).format("HH:mm") })
                                    }}
                                />
                            </Box>
                        </Box>

                        <Box gap="small">
                            <Text size="small">Imagen de la sala</Text>
                            <Box round="7px">
                                <Box background={`url(${imageURL})`} flex="grow" pad="small" align="start" height="250px" round="small">
                                    <Button size="small" style={{ borderRadius: 7 }} primary label="Cambiar Imagen" onClick={() => fileRef.current.click()} />
                                    <input type="file" ref={fileRef} onChange={e => {
                                        setFile(e.target.files[0])
                                        setImageURL(URL.createObjectURL(e.target.files[0]))
                                    }}
                                        style={{ display: "none" }} />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Layer>
    );
}

export default NewRoomModal;