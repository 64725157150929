import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase/app'

const Logout = ({ history }) => {

    useEffect(() => {
        firebase.auth().signOut().then( async resp => {
            await localStorage.clear()
            await history.push("/")
        })
    }, [])
    return null
}

export default Logout